import './ResultScreen.css';

interface ResultScreenProps {
  results: any;
  onRestart: () => void;
  onClose: () => void;
  onFinish: () => void;
  currentRound: number;
}

const StarIcon = ({ active }: { active: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={active ? "gold" : "currentColor"}
    className="star-icon"
  >
    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </svg>
);

const StarRating = ({ rating }: { rating: number }) => (
  <div className='rating'>
    {Array.from({ length: 3 }).map((_, index) => (
      <StarIcon key={index} active={index < rating} />
    ))}
  </div>
);

function ResultScreen({ results, onFinish, onRestart, onClose, currentRound }: ResultScreenProps) {
  return (
    <div className="result-screen">
      <h2>Deine Top 3 Berufsfelder!</h2>

      <div className='tiles'>
        {results.map((result: any, index: number) => (
          <a key={index} href={result.jobUrl} target='_blank'>
            <div className='tile'>
              <img src={result.jobFilename} width={800} height={600} />
              <div className='footer'>
                <div className='left'>
                  <h3>{result.category}</h3>
                  <p>{result.randomJob}</p>
                </div>
                <StarRating rating={result.rating} />
              </div>
            </div>
          </a>
        ))}
      </div>
      <button onClick={onFinish}>Ich möchte meinen Stärkenkompass abschließen!</button>

      {currentRound < 35 && (
        <button onClick={onClose}>Ich möchte mein Ergebnis verfeinern!</button>
      )}
      
    </div>
  );
}

export default ResultScreen;


import React, { useState, useEffect } from 'react';
import ImageSelection from './components/ImageSelection';
import ResultScreen from './components/ResultScreen';
import './App.css';

import imageGroupsData from './data.json'; // Import the JSON file
import jobData from './jobs.json'; // Import the JSON file

interface Image {
  id: number;
  category: string;
  selected: boolean;
  shownCount: number;
}

const initializeImages = (data: any[]): Image[] => {
  return data.map((item, index) => ({
    id: index,
    category: item.category,
    selected: false,
    shownCount: 0,
    filename: item.filename,
  }));
};

const getRandomImages = (images: Image[], count: number): Image[] => {
  const selectedImages: Image[] = [];
  const usedCategories = new Set<string>();

  while (selectedImages.length < count) {
    const randomIndex = Math.floor(Math.random() * images.length);
    const image = images[randomIndex];

    if (!usedCategories.has(image.category) && image.shownCount < 5) {
      selectedImages.push(image);
      usedCategories.add(image.category);
      image.shownCount++;
    }
  }

  return selectedImages;
};

// Function to get job data by category
const getJobDataByCategory = (category: string) => {
  return jobData.find((job) => job.category === category);
};

// Function to get a random job from a list of jobs
const getRandomJob = (jobs: any) => {
  return jobs[Math.floor(Math.random() * jobs.length)];
}

const App: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [selections, setSelections] = useState<number[]>([]);
  const [categorySelections, setCategorySelections] = useState<{ [key: string]: number }>({});
  const [activeScreen, setActiveScreen] = useState('intro');
  const [code, setCode] = useState('');
  const [stats, setStats] = useState({});

  useEffect(() => {
    setImages(initializeImages(imageGroupsData));
  }, []);

  useEffect(() => {
    if(code.length > 2){
      if (Object.keys(stats).length === 0) {
        setStats({
          startTime: new Date(),
          code: code,
          currentRound: null,
          categorySelections: {},
        });
      }
    }
  }, [code]);

  const handleSelection = (selectedImage: any) => {
    setImages(prevImages =>
      prevImages.map(image =>
      image.id === selectedImage.id ? { ...image, selected: true } : image
      )
    );
    setSelections([...selections, selectedImage.id]);

    setCategorySelections(prev => ({
      ...prev,
      [selectedImage.category]: (prev[selectedImage.category] || 0) + 1,
    }));

    setCurrentRound(currentRound + 1);
  };

  useEffect(() => {
    if (currentRound === 12) {
      setActiveScreen('pre-results');
    }

    if (currentRound === 35) {
      setActiveScreen('results');
    }

    if(Object.keys(stats).length > 0){
      setStats(prevStats => ({
        ...prevStats,
        currentRound: currentRound,
        categorySelections: categorySelections,
      }));
    }
  }, [currentRound, categorySelections]);

  const handleRestart = () => {
    setActiveScreen('intro');
    setCurrentRound(0);
    setSelections([]);
    setCategorySelections({});
    setImages(initializeImages(imageGroupsData));
  };

  const handleCloseResults = () => {
    if(currentRound < 35) {
      setActiveScreen('selection');
    }
    
  };

  const handleFinish = () => {
    setActiveScreen('finish');
  };


  const getNextRoundImages = (): Image[] => {
    const unselectedImages = images.filter(img => !img.selected && img.shownCount < 5);
    const uncertainImages = images.filter(img => img.shownCount === 1 && !img.selected);

    if (unselectedImages.length > 0) {
      return getRandomImages(unselectedImages, 4);
    } else if (uncertainImages.length > 0) {
      return uncertainImages.slice(0, 4);
    } else {
      return [];
    }
  };
  const roundImages = getNextRoundImages();

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const extendResultData = (results: any) => {
    let sortedResults = Object.entries(results)
      .map(([category, count]: any) => ({ category, count }))
      .sort((a, b) => b.count - a.count);

    sortedResults = sortedResults.slice(0, 3);

    // Calculate a rating from 1 to 3 based on the count. The highest count gets a rating of 3. If there are equal ratings, use the same rating for multiple results. Add the rating to a copy of the sortedResults array.
    let rating = 3;
    let previousCount: number | null = null;

    const ratedResults = sortedResults.map((result, index) => {
      if (previousCount !== null && result.count < previousCount) {
        rating--;
      }
      previousCount = result.count;
      return { ...result, rating };
    });

    const resultData = ratedResults.map((result, index) => {
      const jobData = getJobDataByCategory(result.category);
      return {
        category: result.category,
        rating: result.rating,
        jobUrl: jobData?.url,
        jobFilename: jobData?.filename,
        randomJob: getRandomJob(jobData?.jobs),
      };
    });

    const updatedStats = { 
      ...stats, 
      finishTime: new Date(),
      suggestedJobs: resultData,
    };
    console.log('Stats for API:', updatedStats);

    // Send stats object as POST request to API
    fetch('https://staerkenkompass.disruptive-elements.com/api/?api_key=98e3d77a-798d-47da-816e-952637c81665', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedStats),
    });

    return resultData;
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Dein Stärkenkompass</h1>
      </header>
      <main>

        {activeScreen === 'intro' && (
          <div className='text-screen'>
            <h1>Welche Berufsfelder passen zu dir?</h1>
            <p>Um das festzustellen, schauen wir erst einmal, welche Tätigkeiten dir gefallen.</p>
            <p>Dafür finden wir gemeinsam heraus, welche Betätigungen und Situationen dich ansprechen.</p>
            <p>Wir zeigen dir 12 x 4 Bilder. Du überlegst nicht lange und klickst das Bild an, das dich am meisten anspricht.</p>
            <p>Es gibt dabei kein Richtig und Falsch und du musst die abgebildete Tätigkeit nicht beherrschen. Sie muss einfach spontan dein Interesse wecken.</p>
            <p><input type="text" placeholder="Gib hier den Code ein." value={code} onChange={handleCodeChange} /></p>
            <button onClick={() => setActiveScreen('selection')} disabled={code.length < 3}>Ich bin bereit!</button>
          </div>
        )}

        {activeScreen === 'selection' && (
          <div className='image-selection-container'>
            <ImageSelection images={roundImages} onSelect={handleSelection} />
            {currentRound < 12 ? (
              <p>Runde {currentRound + 1} von 12</p>
            ) : (
              <p>Runde {currentRound + 1} von max. 36</p>
            )}
            {currentRound > 12 && (
              <button onClick={() => setActiveScreen('results')}>Ich möchte mein Ergebnis sehen!</button>
            )}
          </div>
        )}

        {activeScreen === 'pre-results' && (
          <div className='text-screen'>
            <h1>Geschafft!</h1>
            <p>Schau dir an, welche 3 Berufsfelder am besten zu dir passen!</p>
            <p>Um dein Ergebnis weiter zu verfeinern, kannst du noch ein paar Runden weitermachen!</p>
            <button onClick={() => setActiveScreen('results')}>Ich möchte mein Ergebnis sehen!</button>
            <br />
            <button onClick={() => setActiveScreen('selection')}>Ich möchte mein Ergebnis verfeinern!</button>
          </div>
        )}

        {activeScreen === 'results' && (
          <ResultScreen results={extendResultData(categorySelections)} onFinish={handleFinish} onRestart={handleRestart} onClose={handleCloseResults} currentRound={currentRound} />
        )}

        {activeScreen === 'finish' && (
          <div className='text-screen'>
          <h1>Fast fertig!</h1>
          <p>
            Bitte fülle noch unsere kleine Umfrage aus, damit wir noch besser verstehen, wie wir die App für dich verbessern können!
          </p>
          <br /><br />
          <a className="button" href='https://tinyurl.com/4fadu74w' target='_blank'>Zur Umfrage</a>
        </div>
        )}
      </main>
    </div>
  );
};

export default App;